import React, {Suspense} from 'react';
import {Navigate} from 'react-router-dom';
import type {RouteObject} from 'react-router-dom';

import Promotion from '@/views/collection/Promotion';
import CollectionItems from '@/views/collection/Items';
import CancelFailed from '@/views/order/CancelFailed';
import Proposal from '@/views/home/qna/proposal';
import Report from '@/views/home/qna/report';
import {GiftcardDetail} from '@/views/brand/place/GiftcardDetail';
import UseGiftItem from '@/views/order/components/UseGiftItem';
import {SuccessGiftUse} from '@/views/order/SuccessGiftUse';
import Search from '@/views/search';
import RecommendBrands from '@/views/search/RecommendBrands';
import GiftOptionAlert from '@/views/collection/GiftOptionAlert';
import GiftUseDetail from '@/views/order/GiftUseDetail';
import {PlaceV2} from '@/views/placeV2';
import NPick from '@/views/nPick';
import WrongInformation from '@/views/search/WrongInformation';
import {BrandProvider} from '@/services/contexts/brand/BrandContext';
import Map from '@/views/maps';
import EventPromotion from '@/views/collection/EventPromotion';

import RecommendMain from '../views/recommend/Main';
import RecommendContents from '../views/recommend/Contents';
import FoodLog from '../views/recommend/FoodLog';
import Join from '../views/join';
import UserInfo from '../views/join/UserInfo';
import UserAgree from '../views/join/UserAgree';
import TastTag from '../views/join/TastTag';
import TastTagComplete from '../views/join/TastTagComplete';
import EditProfile from '../views/myPage/EditProfile';
import TastTagSelector from '../views/myKnewnew/TastTagSelector';
import KakaoRedirectHandler from '../views/join/KakaoRedirectHandler';
import CommentList from '../views/comment/List';
import TagInfo from '../views/join/TagInfo';
import SignupComplete from '../views/join/Complete';
import ReportComment from '../views/comment/Report';
import MainHome from '../views/home';
import About from '../views/about';
import CardList from '../views/cardList';
import Curator from '../views/curator/Curator';
import CuratorInfo from '../views/curator/CuratorInfo';
import Options from '../views/myKnewnew/Options';
import EmailJoin from '../views/join/EmailJoin';
import PasswordReset from '../views/join/PasswordReset';
import MagazineMain from '../views/magazine/Main';
import NaverRedirectHandler from '../views/join/NaverRedirectHandler';
import FirstPage from '../views/firstPage';
import PushConfig from '../views/myKnewnew/PushConfig';
import PushList from '../views/myKnewnew/PushList';
import ContentsWrapper from '../views/contents/ContentsWrapper';
import RequestUpdate from '../views/RequestUpdate';
import Order from '../views/order';
import Agreement from '../views/order/Agreement';
import OrderProcess from '../views/order/Process';
import Failed from '../views/order/Failed';
import OrderSuccess from '../views/order/Success';
import OrderList from '../views/order/List';
import OrderDetail from '../views/order/Detail';
import Collections from '../views/collection';
import MyCollection from '../views/collection/MyCollection';
import Payments from '../views/order/Payments';
import ResultRedirection from '../views/authentication/ResultRedirection';
import UserAuthProcess from '../views/authentication/UserAuthProcess';
import Coupon from '../views/myKnewnew/Coupon';
import NumberEnterPage from '../views/order/NumberEnterPage';
import BrandHome from '../views/brand/home/Home';
import BrandList from '../views/brand/list/List';
import EmptyOptionAlert from '../views/collection/EmptyOptionAlert';
import MyPage from '../views/myPage';
import OrderBenefits from '../views/order/Benefits';
import BenefitInfoPage from '../views/brand/benefit/BenefitInfoPage';
import BenefitReceivedPage from '../views/order/BenefitReceivedPage';
import BrandCollections from '../views/brand/place/BrandCollections';
import BenefitDetailPage from '../views/order/BenefitDetailPage';
import MyArticles from '../views/myPage/MyArticles';
import SpecialList from '../views/brand/list/Special';
import Loader from '../components/common/Loader';
import PaymentSuccess from '../components/common/Payment/Success';
import Payment from '../components/common/Payment';
import MyContents from '../components/myKnewnew/MyContents';
import BookMark from '../components/myKnewnew/BookMark';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate replace to="/first" />,
  },
  {
    path: '/proposal',
    element: <Proposal />,
  },
  {
    path: '/report',
    element: <Report />,
  },
  {
    path: '/first',
    element: <FirstPage />,
  },
  {
    path: '/magazine',
    element: <MagazineMain />,
  },
  {
    path: '/recommend',
    element: <RecommendMain />,
  },
  {
    path: '/recommend/:id',
    element: <RecommendContents type="normal" />,
  },
  {
    path: '/recommend/foodlog/:id',
    element: <FoodLog />,
  },
  {
    path: '/recommend/market/:id',
    element: <RecommendContents type="market" />,
  },
  {
    path: '/login',
    element: <Join />,
  },
  {
    path: '/login/auth',
    element: <KakaoRedirectHandler />,
  },
  {
    path: '/auth/naver',
    element: <NaverRedirectHandler />,
  },
  {
    path: '/login/email',
    element: <EmailJoin />,
  },
  {
    path: '/login/password',
    element: <PasswordReset />,
  },
  {
    path: '/signup',
    element: <UserInfo />,
  },
  {
    path: '/signup/complete',
    element: <SignupComplete />,
  },
  {
    path: '/signup/tag',
    element: <TagInfo />,
  },
  {
    path: '/signup/agree',
    element: <UserAgree />,
  },
  {
    path: '/signup/taste-tag',
    element: <TastTag type={'join'} />,
  },
  {
    path: '/signup/tast-tag/complete',
    element: <TastTagComplete />,
  },
  {
    path: '/user',
    element: <MyPage />,
  },
  {
    path: '/user/brands/new',
    element: (
      <Suspense>
        <BrandList />
      </Suspense>
    ),
  },
  {
    path: '/user/brands/special',
    element: (
      <Suspense>
        <SpecialList />
      </Suspense>
    ),
  },
  {
    path: '/user/options',
    element: <Options />,
  },
  {
    path: '/user/edit/taste-tag',
    element: <TastTag type={'edit'} />,
  },
  {
    path: '/user/edit',
    element: <EditProfile />,
  },
  {
    path: '/user/taste-tag',
    element: <TastTagSelector />,
  },
  {
    path: '/comments/:id',
    element: <CommentList />,
  },
  {
    path: '/comments/report/:magazineId/:commentsId',
    element: <ReportComment type={'comment'} />,
  },
  {
    path: '/contents/report/:contentsId',
    element: <ReportComment type={'contents'} />,
  },
  {
    path: '/home',
    element: (
      <Suspense>
        <MainHome />
      </Suspense>
    ),
  },
  {
    path: '/search',
    element: (
      <Suspense>
        <Search />
      </Suspense>
    ),
  },
  {
    path: '/recommend-brand',
    element: <RecommendBrands />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/brand',
    element: (
      <Suspense>
        <BrandHome />
      </Suspense>
    ),
  },
  {
    path: '/brand/:id/:placeId',
    element: (
      <BrandProvider>
        <PlaceV2 />
      </BrandProvider>
    ),
  },
  {
    path: '/brand/giftcard/:id',
    element: <GiftcardDetail />,
  },
  {
    path: '/brands',
    element: (
      <Suspense>
        <BrandList />
      </Suspense>
    ),
  },
  {
    path: '/brands/new',
    element: (
      <Suspense>
        <BrandList />
      </Suspense>
    ),
  },
  {
    path: '/knewbrands',
    element: <CardList type={'brands'} />,
  },
  {
    path: '/zip',
    element: <CardList type="zip" />,
  },
  {
    path: '/news',
    element: <CardList type="news" />,
  },
  {
    path: '/curators',
    element: <Curator />,
  },
  {
    path: '/curators/:curatorId',
    element: <CuratorInfo />,
  },
  {
    path: '/bookmark',
    element: <BookMark />,
  },
  {
    path: '/my-collection',
    element: <MyCollection />,
  },
  {
    path: '/my-collection/no',
    element: <MyCollection />,
  },
  {
    path: '/knewbrands/:brandId',
    element: <ContentsWrapper type={'brand'} />,
  },
  {
    path: '/zip/:zipId',
    element: <ContentsWrapper type={'zip'} />,
  },
  {
    path: '/news/:newsId',
    element: <ContentsWrapper type={'news'} />,
  },
  {
    path: '/popup/:popupId',
    element: <ContentsWrapper type={'issue'} />,
  },
  {
    path: '/event/:id',
    element: <ContentsWrapper type={'event'} />,
  },
  {
    path: '/collection/brand-only/:brandId',
    element: <BrandCollections />,
  },
  {
    path: '/benefit/:benefitId',
    element: <BenefitInfoPage />,
  },
  {
    path: '/my-contents',
    element: <MyContents />,
  },
  {
    path: '/my-articles',
    element: <MyArticles />,
  },
  {
    path: '/push-config',
    element: <PushConfig />,
  },
  {
    path: '/push-list',
    element: (
      <Suspense fallback={<Loader />}>
        <PushList />
      </Suspense>
    ),
  },
  {
    path: '/update',
    element: <RequestUpdate />,
  },
  {
    path: '/order',
    element: <Payment />,
  },
  {
    path: '/order/success',
    element: <PaymentSuccess />,
  },
  {
    path: '/order/gift/success',
    element: <SuccessGiftUse />,
  },
  {
    path: '/order/gift/detail/:id',
    element: <GiftUseDetail />,
  },
  {
    path: '/order/fail',
    element: <Failed type={'failed'} />,
  },
  {
    path: '/cancel/fail',
    element: <CancelFailed />,
  },
  {
    path: '/order/agreement',
    element: <Agreement />,
  },
  {
    path: '/order/process',
    element: <OrderProcess />,
  },
  {
    path: '/order/failed',
    element: <Failed type={'failed'} />,
  },
  {
    path: '/order/soldout',
    element: <Failed type={'soldout'} />,
  },
  {
    path: '/order/userlimit',
    element: <Failed type={'userlimit'} />,
  },
  {
    path: '/order/:id/success',
    element: <OrderSuccess />,
  },
  {
    path: '/benefit/:id/success',
    element: <BenefitReceivedPage />,
  },
  {
    path: '/order/detail/:id',
    element: <Order />,
  },
  {
    path: '/order/detail/payments/:id',
    element: <Payments />,
  },
  {
    path: '/order/list',
    element: <OrderList />,
  },
  {
    path: '/order/benefits',
    element: <OrderBenefits />,
  },
  {
    path: '/order/benefit/:id',
    element: <BenefitDetailPage />,
  },
  {
    path: '/order/gift/use/:id',
    element: <UseGiftItem />,
  },
  {
    path: '/order/numbers',
    element: <NumberEnterPage />,
  },
  {
    path: '/order/emptyoptions/:id',
    element: <EmptyOptionAlert />,
  },
  {
    path: '/event/promotion/:id',
    element: <EventPromotion />,
  },
  {
    path: '/giftcard/wait/:id',
    element: <GiftOptionAlert />,
  },
  {
    path: '/user/order/info',
    element: <OrderDetail />,
  },
  {
    path: '/collection/list',
    element: <Collections />,
  },
  {
    path: '/collection/items/:type',
    element: <CollectionItems />,
  },
  {
    path: '/collection/promotion-details/:id',
    element: <Promotion />,
  },
  {
    path: '/user/auth/result',
    element: <ResultRedirection />,
  },
  {
    path: '/user/auth/process',
    element: <UserAuthProcess />,
  },
  {
    path: '/user/coupon',
    element: <Coupon />,
  },
  {
    path: '/promotion',
    element: <Promotion />,
  },
  {
    path: '/n-pick',
    element: <NPick />,
  },
  {
    path: '/wrong-info/:id/:placeId',
    element: (
      <BrandProvider>
        <WrongInformation />
      </BrandProvider>
    ),
  },
  {
    path: '/map',
    element: <Map />,
  },
  {
    path: '*',
    element: <Navigate to="/home" />,
  },
];

export default routes;
